import { forwardRef } from 'react';
import { badge } from './badge.css';
import clsx from 'clsx';

export type BadgeProps = {
  color?: keyof typeof badge;
  interactive?: boolean;
} & React.ComponentPropsWithoutRef<'span'>;

export const Badge = forwardRef<HTMLSpanElement, BadgeProps>(function Badge(
  { color = 'grey', interactive, className, ...rest },
  ref,
) {
  return (
    <span
      ref={ref}
      data-interactive={interactive ? '' : undefined}
      className={clsx(badge[color], className)}
      {...rest}
    />
  );
});
